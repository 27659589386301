
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import Overview from "@/view/pages/resources/documentation/general/bootstrap-icons/Overview.vue";
import Usage from "@/view/pages/resources/documentation/general/bootstrap-icons/Usage.vue";
import IconColors from "@/view/pages/resources/documentation/general/bootstrap-icons/IconColors.vue";
import IconSizes from "@/view/pages/resources/documentation/general/bootstrap-icons/IconSizes.vue";

export default defineComponent({
  name: "bootstrap-icons",
  components: {
    Overview,
    Usage,
    IconColors,
    IconSizes,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Bootstrap Icons");
    });
  },
});
